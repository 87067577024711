<template>
  <button
    class="cart-control ui-btn flex--col-center-center"
    @click.stop="emitClick"
    aria-label="Open the cart page"
  >
    <Cart
      class="cart-control__icon-placeholder"
      :class="{
        'cart-control__icon-placeholder--fade': getShouldFade
      }"
    />
    <div
      v-if="getCartTotalQuantity > 0"
      class="cart-control__icon-wrapper_count text--w-demi flex--col-center-center noselect"
    >
      <span>
        {{ getCartTotalQuantity }}
      </span>
    </div>
  </button>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import Cart from "./../../assets/cart_icon.svg"

export default {
  props: {
    active: Boolean
  },

  components: {
    Cart
  },

  methods: {
    emitClick(e) {
      this.$emit("click", e)
    }
  },

  computed: {
    ...mapGetters("cart", ["getCartTotalQuantity"]),
    ...mapState("ui", ["navActiveElement"]),
    ...mapGetters("breakpoints", ["GET_IS_BELOW_BREAKPOINT"]),

    getShouldFade() {
      return (
        this.GET_IS_BELOW_BREAKPOINT("md") &&
        this.navActiveElement &&
        this.navActiveElement !== "cart"
      )
    }
  }
}
</script>

<style lang="scss">
.cart-control {
  cursor: pointer;
  position: relative;
  margin: 0 -8px;
  padding: 0 8px;
  pointer-events: all;

  &__icon-placeholder {
    height: 22px;
    transform: translateY(-10%);
    mix-blend-mode: difference;
    transition: opacity 0.3s linear;

    &--fade {
      opacity: 0.5;
    }

    path {
      vector-effect: non-scaling-stroke;
      stroke: $bleach-white;
      stroke-width: 2px;
    }

    @include media-breakpoint-up(xl) {
      height: 26px;
    }
  }

  &__icon-wrapper_count {
    position: absolute;
    top: 63%;
    left: 90%;
    background-color: $bleach-lime;
    color: $bleach-black;
    border-radius: 22px;
    width: 13px;
    height: 13px;
    font-size: 7px;

    transform: translate(-50%, -50%);

    @include media-breakpoint-up(lg) {
      height: 18px;
      width: 18px;
      top: 70%;
      left: 95%;
    }
  }
}
</style>
