<template>
  <button
    class="menu-control ui-btn"
    @click.stop="handleClick"
    aria-label="Show the menu"
    :class="{
      'menu-control--fade': getShouldFade
    }"
  >
    <div
      id="bleach-menu-icon"
      class="menu-icon-placeholder"
      :class="{
        open: active
      }"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
</template>

<script>
import GTMAPI from "@/integration/GTMAPI"

import { mapState } from "vuex"

export default {
  props: {
    active: Boolean
  },

  methods: {
    handleClick(e) {
      if (!this.active) {
        GTMAPI.trackEvent("navigation.hamburger.clicked")
      }
      this.$emit("click", e)
    }
  },

  computed: {
    ...mapState("ui", ["navActiveElement"]),

    getShouldFade() {
      return this.navActiveElement && this.navActiveElement !== "menu"
    }
  }
}
</script>

<style lang="scss">
$stroke-width: 2px;

.menu-control {
  position: relative;
  cursor: pointer;
  margin: 0 -8px;
  padding: 0 8px;
  pointer-events: all;
  transition: opacity 0.3s linear;

  &--fade {
    opacity: 0.5;
  }
}

.menu-icon-placeholder {
  position: relative;
  fill: getcolour(bleach_white);
  mix-blend-mode: difference;
  height: 16px;
  width: 22px;

  @include media-breakpoint-up(md) {
    width: 24px;
    height: 18px;
  }
}

#bleach-menu-icon {
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;
}

#bleach-menu-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: getcolour(bleach_white);

  opacity: 1;
  left: 0;

  transform: rotate(0deg);

  transition: 0.25s ease-in-out;
}

#bleach-menu-icon span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

#bleach-menu-icon span:nth-child(2) {
  top: calc(50% - (#{$stroke-width} / 2));
  transform-origin: left center;
}

#bleach-menu-icon span:nth-child(3) {
  bottom: 0px;
  transform-origin: left center;
}

#bleach-menu-icon.open span:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: center;
  top: calc(50% - (#{$stroke-width} / 2));
}

#bleach-menu-icon.open span:nth-child(2) {
  opacity: 0;
}

#bleach-menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
  transform-origin: center;
  bottom: calc(50% - (#{$stroke-width} / 2));
}
</style>
